import React from 'react';
import Loading from './Loading';
import Icon from '../Icon/Icon';
import { LOADING_FACTS } from '../../constants';
import If from '../If/If';
import CybraryLogo from '../CybraryLogo/CybraryLogo';

const loadingFact = LOADING_FACTS[Math.floor(Math.random() * LOADING_FACTS.length)];

function LoadingFacts({ message }) {
  return (
    <>
      <CybraryLogo />
      <div className="flex flex-col space-y-12 h-1/2">
        <div className="mx-auto max-w-xl">
          <If condition={!!loadingFact}>
            <div className="px-4">
              <div className="flex items-baseline space-x-2">
                <If condition={loadingFact?.icon}>
                  <Icon className="w-6 h-6 fill-cyb-pink-500" name={loadingFact.icon} />
                </If>
                <If condition={loadingFact?.title}>
                  <p className="mt-auto mb-0 text-xl font-semibold">{loadingFact.title}</p>
                </If>
              </div>

              <If condition={loadingFact?.description}>
                <p className="ml-8">{loadingFact.description}</p>
              </If>
            </div>
          </If>
        </div>
        <Loading className="w-16 h-16 border-8" message={message} />
      </div>
    </>
  );
}

export default LoadingFacts;
