import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import DropdownContainer from './DropdownContainer';
import TeamsIcon from '../../Icons/TeamsIcon';
import Tooltip from '../Tooltip/Tooltip';
import ChevronDown from '../../Icons/ChevronDown';
import ChevronRight from '../../Icons/ChevronRight';
import LoadingSkeleton from '../Loading/LoadingText';
import FormatUtil from '../../utils/formatUtil';
import If from '../If/If';

function TeamsSelections({ teams, switchTeam, logoClasses, mobileNav }) {
  return (
    <>
      {Object.keys(teams).map((teamId, idx) => {
        const currTeam = teams[teamId];
        return (
          <button key={teamId} onClick={() => switchTeam(currTeam, teamId)} className={`py-4 cursor-pointer w-full text-left ${idx > 0 ? 'border-t-1 border-gray-300' : ''}`}>
            <div className="py-1 px-8 hover:bg-gray-50">
              <TeamLogo logo={currTeam.logo_url} classes={logoClasses} />
              <p className={`${!mobileNav ? 'text-sm' : 'text-lg'} align-middle ml-2 mb-0 inline-block hover:text-gray-900 text-gray-600`}>{currTeam.name}</p>
            </div>
          </button>
        );
      })}
    </>
  );
}

function TeamLogo({ logo, classes }) {
  if (!logo) {
    return <TeamsIcon filled inverted classes={`p-1 bg-black ${classes}`} id="-team-logo" />;
  }

  return <img className={classes} src={logo} alt="Team Logo" />;
}

function TeamsDropdown({ userStore, mobileNav, position = 'left', isLeaderboardDrawerOpen, toggleLeaderboardDrawer, isLeaderboardEnabled, isAdminOnly }) {
  if (!userStore) {
    return null;
  }

  const [teams, setTeams] = useState(null);
  const [team, setTeam] = useState(null);
  const [teamDropdown, setTeamDropdown] = useState(false);

  useEffect(() => {
    const { teamsWithAdminAccess, adminTeam, userTeams, team: currentTeam } = userStore;

    if (isAdminOnly) {
      if (!teamsWithAdminAccess || !Object.keys(teamsWithAdminAccess).length) {
        return;
      }
      setTeams(teamsWithAdminAccess);
      setTeam(adminTeam);
    } else if (!!userTeams && !!currentTeam) {
      setTeams(userTeams);
      setTeam(currentTeam);
    }
  }, []);

  if (!team || !teams) {
    return (
      <div className="flex relative items-center px-2 secondary-nav-teams">
        <div className="w-40 h-8">
          <LoadingSkeleton wrapperClassName="absolute inset-0 p-0" className="w-full h-full rounded" />
        </div>
      </div>
    );
  }

  const selectedTeam = team;
  const hasMultipleTeams = Object.keys(teams).length > 1;
  const selectorClasses = hasMultipleTeams ? 'cursor-pointer' : '';
  const selectorClickProp = hasMultipleTeams ? { onClick: () => setTeamDropdown(!teamDropdown) } : {};
  const logoClasses = 'w-8 mr-2 border border-gray-300 rounded inline-block';
  const chevronTransformClass = teamDropdown ? 'transform rotate-180 text-gray-900' : '';
  const dropdownContainerClasses = position === 'left' ? 'left-0' : 'right-0';

  // Update to the current team
  const switchTeam = (newTeam, newTeamId) => {
    if (newTeamId !== selectedTeam.id) {
      // We are actually switching
      userStore.setTeam(newTeam);
      userStore.setTeamPermissions(newTeamId);
      userStore.setMetaPreferenceTeam(newTeamId);
      if (window.location.pathname.includes('enterprise')) {
        window.location = `/enterprise/${newTeamId}`;
      } else {
        window.location = '/';
      }
    } else {
      setTeamDropdown(false);
    }
  };

  if (!mobileNav) {
    return (
      <div className="flex relative items-center px-2 secondary-nav-teams">
        <div className={`mr-2 flex items-center ${selectorClasses}`} {...selectorClickProp}>
          <TeamLogo logo={selectedTeam.logo_url} classes={logoClasses} />
          <span className="text-sm font-semibold text-black">{FormatUtil.formatLongText(selectedTeam.name, 35)}</span>
          <If condition={hasMultipleTeams}>
            <button className="leading-4" aria-label="Toggle view teams">
              <ChevronDown classes={twMerge('inline-block ml-2 w-5 h-5 hover:text-gray-900 text-gray-600', chevronTransformClass)} />
            </button>
          </If>
        </div>
        <If condition={hasMultipleTeams}>
          <DropdownContainer classes={twMerge('top-12 pt-8 pb-4', dropdownContainerClasses)} width="25rem" visible={teamDropdown} onClose={() => setTeamDropdown(!teamDropdown)}>
            <p className="px-8 mb-4 text-xs uppercase">Teams</p>
            <TeamsSelections switchTeam={switchTeam} teams={teams} logoClasses={logoClasses} />
          </DropdownContainer>
        </If>
        <If condition={isLeaderboardEnabled}>
          <div className="flex flex-row items-center">
            <div className="w-1 h-full bg-neutral-500" />
            <Tooltip
              triggerContent={
                <div className="flex flex-row items-center">
                  <button
                    className="group"
                    aria-label="Toggle Teams Leaderboard"
                    onClick={() => (isLeaderboardEnabled ? toggleLeaderboardDrawer(!isLeaderboardDrawerOpen) : false)}
                    disabled={!isLeaderboardEnabled}
                  >
                    <ChevronRight classes="w-5 h-5 text-gray-900 transition-all duration-200 ease-out group-hover:translate-x-1" />
                  </button>
                </div>
              }
              content="Toggle Teams Leaderboard"
            />
          </div>
        </If>
      </div>
    );
  }

  return (
    <div className="border-b border-gray-300">
      <div className={`pb-6 px-6 ${selectorClasses}`} {...selectorClickProp}>
        <div className="flex relative items-center space-between">
          <div className="flex-1">
            <TeamLogo logo={selectedTeam.logo_url} classes={logoClasses} />
            <span className="text-lg font-semibold text-black align-middle">{FormatUtil.formatLongText(selectedTeam.name, 35)}</span>
          </div>
          <If condition={hasMultipleTeams}>
            <>
              <button aria-label="Toggle view teams">
                <ChevronDown classes={`inline-block w-6 h-6 hover:text-gray-900 text-gray-600 ${chevronTransformClass}`} />
              </button>
              <DropdownContainer classes="left-0 top-10" width="100%" visible={teamDropdown} onClose={() => setTeamDropdown(!teamDropdown)}>
                <TeamsSelections switchTeam={switchTeam} teams={teams} logoClasses={logoClasses} mobileNav />
              </DropdownContainer>
            </>
          </If>
        </div>
      </div>
    </div>
  );
}

export default TeamsDropdown;
