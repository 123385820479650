import React, { useEffect, useMemo } from 'react';
import TeamOnboardingStep from '../../TeamOnboardingStep';
import { TEAM_ONBOARDING_OPTIONS, useTeamOnboarding } from '../../../../providers/TeamOnboardingProvider';
import Loading from '../../../Loading/Loading';

function FinishedStep() {
  const { saveTeamOnboardingData, teamLearningApproach } = useTeamOnboarding();

  const message = useMemo(() => {
    switch (teamLearningApproach) {
      case TEAM_ONBOARDING_OPTIONS.teamLearningApproach.STRUCTURED_PLAN.key:
        return 'Great! Our Goals feature will help you track and achieve them.';
      case TEAM_ONBOARDING_OPTIONS.teamLearningApproach.RECOMMENDATIONS.key:
        return `We’d love to help! Let’s explore how your team can make the most of Cybrary through Baselining, Career Paths, 
        Certification Prep, or Custom Learning Paths to best align with your learning objectives.`;
      case TEAM_ONBOARDING_OPTIONS.teamLearningApproach.PROVIDE_RESOURCES.key:
        return 'No problem! Cybrary provides open access to a wide range of resources. Your Customer Success Manager is here to assist if needed.';
      default:
        return 'Loading your team...';
    }
  }, [teamLearningApproach]);

  useEffect(() => {
    saveTeamOnboardingData();
  }, [saveTeamOnboardingData]);

  return (
    <TeamOnboardingStep title="All Finished!" isNextStepShown={false} isPrevStepShown={false}>
      <Loading className="mt-8 w-16 h-16 border-8" message={message} />
    </TeamOnboardingStep>
  );
}

export default FinishedStep;
