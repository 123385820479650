import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Container from './Container';
import Icon from '../Icon/Icon';
import If from '../If/If';

const BLUR_INTENSITY_CLASSES = {
  sm: 'backdrop-blur-sm',
  md: 'backdrop-blur-md',
  lg: 'backdrop-blur-lg',
};

/**
 * Wrapper that can lock access content
 * @param {string} children - The element to be wrapped
 * @param {string} id - ID to apply to the container
 * @param {string} msg - Message prompt
 * @param {boolean} isLocked - Whether the container is locked
 * @param {string} isFullWidth - Whether the container should be full width
 * @param {number} intensity - Blur intensity level - valid values = `"sm", "md", "lg"`
 * @param {boolean} showLock - Whether or not to actually show the lock icon, defaults to true
 * @returns <LockedContainer />
 *
 * @abstract -
 * `inline-block` class allows container to responsively use the width of the children,
 * however, this means if these <LockedContainer /> are used consectively, they will always be laid out inline horizontally,
 * unless wrapped in a parent container to dictate the flow
 */
function LockedContainer({ children, id, msg, isLocked, isFullWidth, intensity = 'sm', showLock = true }) {
  /**
   * Generate the class names for the overlay based on the props
   */
  const classNamesForOverlay = useMemo(() => {
    let classes = 'flex absolute inset-0 flex-col justify-center items-center bg-white/50 m-[1px] px-3 text-center';

    // apply `backdrop-blur` based on intensity value
    classes = twMerge(classes, BLUR_INTENSITY_CLASSES[intensity]);

    return classes;
  }, [intensity]);

  return (
    <Container id={id} className="inline-block relative mx-0 h-fit" full={isFullWidth} omitPadding>
      {children}
      <If condition={isLocked}>
        <div className={classNamesForOverlay}>
          <If condition={showLock}>
            <Icon name="lock" className="w-12 h-12" />
          </If>
          <If condition={Boolean(msg)}>
            <p className="mt-3 font-bold">{msg}</p>
          </If>
        </div>
      </If>
    </Container>
  );
}

export default LockedContainer;
