import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import CybAssessmentProvider from '../../providers/CybAssessmentProvider';
import ClabProvider from '../../providers/ClabProvider';
import ImmersiveTasksProvider from '../../providers/ImmersiveTasksProvider';
import { useImmersive } from '../../providers/ImmersiveProvider';
import ImmersiveRenderer from '../../components/Immersive/ImmersiveRenderer';
import LoadingPage from '../LoadingPage/LoadingPage';

/**
 * This page controls the immersive when we have an enrollment activity, aka a stand-alone activity that acts as it's own enrollment (i.e. a Virtual Lab launched directly).
 * We load the enrollment, then the enrollment activity, and display the Immersive UI.
 * Example url: /immersive/enrollment/activity/:enrollmentActivityId
 */
const ImmersiveEnrollmentActivity = inject(
  'userStore',
  'authStore',
  'commonStore',
  'goalsStore'
)(
  observer(({ userStore, commonStore, authStore, onCompletePage = false }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { enrollment, loadingEnrollment, loadingActivity, loadEnrollment, activity, loadEnrollmentActivity, outline, menu, reset } = useImmersive();

    const { enrollmentActivityId } = params;

    useEffect(() => {
      // Remove the app's main navigation by letting the common store know we are in the immersive ux
      commonStore.setIsInImmersive(true);
      return () => {
        reset();
        commonStore.setIsInImmersive(false);
      };
    }, []);

    // Start by loading the stand alone activity enrollment into the immersive provider
    useEffect(() => {
      if (!enrollmentActivityId) {
        return;
      }

      loadEnrollment(1 * enrollmentActivityId, true);
    }, [enrollmentActivityId]);

    // Then load the activity into the immersive provider
    useEffect(() => {
      if (!enrollment || !enrollmentActivityId || loadingActivity) {
        return;
      }

      loadEnrollmentActivity();
    }, [enrollmentActivityId, enrollment, loadingActivity]);

    if (loadingActivity || loadingEnrollment || !outline || !menu || !activity) {
      return <LoadingPage message="Loading your enrollment activity..." />;
    }

    const { clabVersion } = activity;

    return (
      <ClabProvider version={clabVersion}>
        <CybAssessmentProvider>
          <ImmersiveTasksProvider>
            <ImmersiveRenderer
              userStore={userStore}
              commonStore={commonStore}
              authStore={authStore}
              navigate={navigate}
              enrollment={enrollment}
              activity={activity}
              menu={menu}
              onCompletePage={onCompletePage}
              clabVersion={clabVersion}
            />
          </ImmersiveTasksProvider>
        </CybAssessmentProvider>
      </ClabProvider>
    );
  })
);

export default ImmersiveEnrollmentActivity;
