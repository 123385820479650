import Guacamole from 'guacamole-common-js';
import { Event } from '../agents';
import { isFirefox } from '../utils/helpers';

export default class GuacConnection {
  client;

  tunnel;

  connectionString = '';

  mouse;

  keyboard;

  input = '';

  userData;

  error;

  withCredentials;

  ONENTER = 65293;

  disableKeyboardEvents = true;

  constructor(url, userData) {
    this.withCredentials = process.env.REACT_APP_BASE_URL !== process.env.REACT_APP_CLAB_API_URL;
    const tunnel = new Guacamole.WebSocketTunnel(url);
    this.tunnel = tunnel;
    this.client = new Guacamole.Client(tunnel);
    this.userData = userData;
  }

  /**
   * Convert param object to string
   * @param params
   */
  // private paramsToString(params: ConnectionParams) {
  paramsToString(params) {
    this.connectionString = params;
  }

  /**
   * Setup the connection to guac, establishing mouse/keyboard control, error handling, and clipboard functionality
   * @param {string} elementId - The ID of the guac container in the DOM.
   * @param {function} errorHandler The error handling function to pass to guac.
   */
  setupConnection(elementId, errorHandler) {
    const displayElement = this.client.getDisplay().getElement();
    this.mouse = new Guacamole.Mouse(displayElement);

    const el = document.getElementById(elementId);
    this.keyboard = new Guacamole.Keyboard(el);

    this.tunnel.onerror = (error) => {
      this.error = error;
      if (errorHandler) {
        errorHandler(error.message);
      }
    };

    this.client.onerror = (error) => {
      this.error = error;
      if (errorHandler) {
        errorHandler(error.message);
      }
    };

    this.client.onclipboard = (stream, type) => {
      if (type === 'text/plain') {
        const reader = new Guacamole.StringReader(stream);
        let str = '';
        reader.ontext = (newText) => {
          str = `${str}${newText}`;
        };
        reader.onend = () => {};
      }
    };
  }

  /**
   * Connect to Guac using legacy clab, and set the mouse and keyboard
   * @param params
   */
  connect(params, ip, errorHandler) {
    this.paramsToString(params);
    this.client.connect(this.connectionString);

    this.setupConnection(ip, errorHandler);
  }

  /**
   * Connect to Guac using the new clab, and set the mouse and keyboard
   * @param {object} params - guacParams object returned from the machine, prsently contains token
   * @param {int} width - width in pixels of the guac containing element
   * @param {int} height - height in pixels of the guac containing element
   * @param {string} connectionId - ID of the guac container in the DOM
   * @param {function} errorHandler - error handling function for guac errors
   */
  connectToNewClab(params, width, height, connectionId, errorHandler) {
    // Append token, width and height to the provided connection url and connect
    const connectString = `width=${width}&height=${height}&token=${params.token}`;
    this.client.connect(connectString);
    // Not saving keyboard events in the new clab, if we decide to change this, just set this to false
    this.disableKeyboardEvents = true;

    this.setupConnection(connectionId, errorHandler);
  }

  /**
   * Start mouse event listener
   */
  startMouseListener() {
    const adjustMouseState = (mouseState, xOffset) => {
      // If this is firefox, just return the mouseState, there's no offset needed
      if (isFirefox()) {
        return mouseState;
      }
      return {
        ...mouseState,
        x: mouseState.x - xOffset,
        y: mouseState.y - 64,
      };
    };

    this.mouse.onmousedown = (mouseState) => {
      const xOffset = window.innerWidth - this.client.getDisplay().getElement().offsetWidth;
      this.client.sendMouseState(adjustMouseState(mouseState, xOffset));
    };
    this.mouse.onmouseup = (mouseState) => {
      const xOffset = window.innerWidth - this.client.getDisplay().getElement().offsetWidth;
      this.client.sendMouseState(adjustMouseState(mouseState, xOffset));
    };
    this.mouse.onmousemove = (mouseState) => {
      const xOffset = window.innerWidth - this.client.getDisplay().getElement().offsetWidth;
      this.client.sendMouseState(adjustMouseState(mouseState, xOffset));
    };
  }

  saveKeyBoardEvents() {
    if (!this.disableKeyboardEvents) {
      const keyEvent = { ...this.userData, keyValue: this.input, created: Date.now(), keyCode: this.ONENTER };
      Event.saveUserKeyEvent(keyEvent);
    }
    this.input = '';
  }

  /**
   * Start keyboard event listener
   */
  startKeyboardListener() {
    this.keyboard.onkeydown = (keysym) => {
      this.client.sendKeyEvent(1, keysym);
    };

    // On enter, save user input
    this.keyboard.onkeyup = (keysym) => {
      if (keysym === this.ONENTER) {
        this.saveKeyBoardEvents();
      } else {
        this.input += String.fromCharCode(keysym);
      }
      this.client.sendKeyEvent(0, keysym);
    };
  }

  /**
   * Resize guacamole terminal
   * @param containerRef
   * @returns
   */
  resizeTerminal(containerElement) {
    if (!containerElement) {
      return;
    }
    const width = Math.round(containerElement.clientWidth);
    const height = Math.round(containerElement.clientHeight);
    this.client.sendSize(width, height);
  }

  /**
   * Send text to the guac clipboard
   */
  sendToClipboard(value) {
    const stream = this.client.createClipboardStream('text/plain');
    const writer = new Guacamole.StringWriter(stream);
    writer.sendText(value);
    writer.sendEnd();
  }
}
