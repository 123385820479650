import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import TeamOnboardingRouter from './OnboardingRouters/TeamOnboardingRouter';
import OnboardingContainer from './OnboardingContainer';
import { useTeamOnboarding } from '../../providers/TeamOnboardingProvider';

const TeamOnboardingPage = inject(
  'commonStore',
  'userStore'
)(
  observer(({ commonStore, userStore }) => {
    const params = useParams();
    const teamId = params?.teamId;
    const navigate = useNavigate();

    const { setTeam, isSubmitting } = useTeamOnboarding();

    const onExit = () => {
      commonStore.showPrimaryNav();
      commonStore.setHideFooter(false);
    };

    useEffect(() => {
      // Hide the primary nav and footer
      commonStore.hidePrimaryNav();
      commonStore.setHideFooter(true);

      // Check if we have a teamId and user has access to manage onboarding for the team
      if (!teamId) {
        // This means we navigated to /onboarding/teams without a teamId. Lets find the first team the user has access to if any
        const firstTeamWithOnboardingAccessId = Object.keys(userStore?.teamsWithOnboardingAccess || {})?.[0];
        if (firstTeamWithOnboardingAccessId) {
          navigate(`/onboarding/teams/${firstTeamWithOnboardingAccessId}`);
        } else {
          // Redirect to dashboard if no teamId or user does not have access to manage onboarding for the team
          navigate('/');
        }
        return onExit;
      }

      // If we are submitting, do not proceed. Wait for submission to complete
      if (isSubmitting) {
        return onExit;
      }

      // Check if we can manage this team.
      const selectedTeam = userStore?.teamsWithOnboardingAccess?.[teamId];

      // Redirect to dashboard if no teamId or user does not have access to manage onboarding for the team
      if (teamId && !selectedTeam) {
        navigate('/');
        return onExit;
      }

      // Set team in team onboarding context
      setTeam(selectedTeam);

      return onExit;
    }, [userStore?.user, teamId]);

    return (
      <OnboardingContainer>
        <TeamOnboardingRouter />
      </OnboardingContainer>
    );
  })
);

export default TeamOnboardingPage;
