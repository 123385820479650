import React, { useState } from 'react';
import { useClab } from '../../../../providers/ClabProvider';
import Modal from '../Modal/modal';

export default function ClipboardForm() {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState('');
  const { endLab } = useClab();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const sendClipboard = () => {
    const event = new CustomEvent('copyToRemote', { detail: { str: value } });
    window.dispatchEvent(event);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const forceRestart = async () => {
    setModalOpen(false);
    await endLab();
    window.location.reload();
  };

  return (
    <>
      <div className="w-full">
        <div>
          <button className="block py-2 mb-4 w-full text-base font-semibold text-white bg-red-600 hover:bg-red-500 rounded" type="button" onClick={openModal}>
            Restart Lab
          </button>
        </div>
        <label htmlFor="clipboardtext" className="block mb-2 w-full text-base font-bold text-gray-800">
          Clipboard
        </label>
        <textarea id="clipboardtext" name="clipboardtext" rows={5} cols={5} className="p-2 w-full bg-blue-50 rounded-xl border border-blue-200" onChange={onChange} value={value} />
        <button className="block py-2 mt-2 w-full text-base font-semibold text-white bg-cyb-pink-600 hover:bg-cyb-pink-500 rounded" type="button" onClick={sendClipboard}>
          Send to System
        </button>
      </div>
      <Modal open={modalOpen} onClose={closeModal} className="max-w-sm">
        <h3 className="mb-8 text-2xl font-bold text-gray-200">Restart lab?</h3>
        <p className="mb-6 text-gray-200">All changes you to your virtual machine will be lost.</p>
        <div className="flex justify-between">
          <button className="px-8 h-10 text-xs font-semibold bg-white rounded" type="button" onClick={closeModal}>
            Nevermind
          </button>
          <button className="px-8 h-10 text-xs font-semibold text-white bg-pink-700 rounded" type="button" onClick={forceRestart}>
            Restart Lab
          </button>
        </div>
      </Modal>
    </>
  );
}
