import React from 'react';

const CHECKOUT_ANNUAL_SAVINGS_PROMO = {
  // Enable or disable the promo
  isEnabled: true,

  // Show the promo on specific pages. Uses regex matching on the current pathname
  isShownOnPages: ['/upgrade/checkout', 'upgrade/checkout/monthly'],

  // Show the promo based on the user's role/state
  isShownPublic: true,

  // Set Snowplow tracking labels
  spLabel: 'upgradeAnnualSubscriptionSavingsPromo',
  spCategory: 'CIPUpgradeBanner',

  // Configure the attributes of the banner
  title: '',
  body: (
    <p className="text-2xl font-semibold text-white" title="Save $120 USD when you switch to 'Billed Annually' below">
      <span className="underline">Save $120</span> when you subscribe annually!
    </p>
  ),
  iconName: 'cost-saving',
  iconClassName: 'w-10 h-10',
  bannerClassName: 'from-cyb-purple-500 to-cyb-blue-500 min-h-0',
  ctaButtonClassName: 'hidden',

  // (Optional) Background image for the banner
  backgroundImageUrl:
    'https://images.ctfassets.net/kvf8rpi09wgk/2QaJW4IZaUsALoDZCKUDM3/5e91eb003a8a01fac5ace0847ab2ad67/CybraryOnDemand_Network_Troubleshooting_Tools_BG.png?h=250', // Optional
  backgroundImageFade: 0.4, // Optional - Default: 0.6 = 60% opacity black overlay
};

export default CHECKOUT_ANNUAL_SAVINGS_PROMO;
