import React from 'react';
import FormatUtil from '../../utils/formatUtil';
import Tooltip from '../Tooltip/Tooltip';
import TeamsIcon from '../../Icons/TeamsIcon';
import Icon from '../Icon/Icon';
import If from '../If/If';

function TeamIconDisplay({ logoUrl }) {
  if (logoUrl) {
    return <img className="w-full" src={logoUrl} alt="" />;
  }
  return <TeamsIcon filled inverted classes="bg-black p-1" />;
}

function TeamNavLogos({ teams, isLeaderboardDrawerOpen, toggleLeaderboardDrawer, isLeaderboardEnabled }) {
  if (!teams || typeof teams !== 'object' || Array.isArray(teams)) {
    return null;
  }

  const teamIds = Object.keys(teams);
  const isMultipleTeams = teamIds.length > 1;
  const iconContainerBaseClasses = 'flex overflow-hidden items-center w-8 h-8 bg-white rounded border border-gray-300 drop-shadow';

  // If on multiple teams, show logos stacked and count of teams. Show all team names in tooltip
  if (isMultipleTeams) {
    return (
      <Tooltip
        triggerContent={
          <button
            className="group"
            aria-label="Toggle Teams Leaderboard"
            onClick={() => (isLeaderboardEnabled ? toggleLeaderboardDrawer(!isLeaderboardDrawerOpen) : false)}
            disabled={!isLeaderboardEnabled}
          >
            <div className="flex items-center h-12 text-sm">
              {teamIds.map((teamId) => (
                <span className={`${iconContainerBaseClasses} -ml-4`} key={teamId}>
                  <TeamIconDisplay logoUrl={teams[teamId].logo_url} />
                </span>
              ))}
              <span className="ml-4 font-semibold">{teamIds.length} Teams</span>
              <If condition={isLeaderboardEnabled}>
                <Icon name="chevron-right" className="ml-2 w-6 h-6 transition-all duration-200 ease-out group-hover:translate-x-1" />
              </If>
            </div>
          </button>
        }
        content={teamIds.map((teamId) => {
          return (
            <p key={teamId} className="mb-2 text-sm font-semibold">
              {teams[teamId].name}
            </p>
          );
        })}
      />
    );
  }

  const team = teams[teamIds[0]];
  const nameLengthLimit = 20;
  const longTeamName = team.name.length > nameLengthLimit;
  // If the team name is over 20 chars, truncate and show full name in tooltip
  return (
    <Tooltip
      disabled={!longTeamName}
      position="bottom"
      triggerContent={
        <button
          className="group"
          aria-label="Toggle Teams Leaderboard"
          onClick={() => (isLeaderboardEnabled ? toggleLeaderboardDrawer(!isLeaderboardDrawerOpen) : false)}
          disabled={!isLeaderboardEnabled}
        >
          <div className="flex gap-x-4 items-center h-12 text-sm font-semibold">
            <span className={iconContainerBaseClasses}>
              <TeamIconDisplay logoUrl={team.logo_url} />
            </span>
            <span>{FormatUtil.formatLongText(team.name, nameLengthLimit)}</span>
            <If condition={isLeaderboardEnabled}>
              <Icon name="chevron-right" className="ml-2 w-6 h-6 transition-all duration-200 ease-out group-hover:translate-x-1" />
            </If>
          </div>
        </button>
      }
      content={teamIds.map((teamId) => {
        return (
          <p key={teamId} className="mb-0 text-sm font-semibold">
            {team.name}
          </p>
        );
      })}
    />
  );
}

export default TeamNavLogos;
