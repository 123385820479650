import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useImmersive } from '../../providers/ImmersiveProvider';
import LoadingPage from '../LoadingPage/LoadingPage';

/**
 * This page controls the immersive when we have nothing but an enrollment ID in the url.
 * We load the enrollment, and redirect to the appropriate route.
 * Example url: /immersive/:enrollmentId
 */
const ImmersiveJustEnrollment = inject('commonStore')(
  observer(({ commonStore }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { enrollment, loadingEnrollment, loadEnrollment, findFirstIncompleteItemId, leaveImmersiveWithError, findFirstIncompleteActivityId } = useImmersive();

    useEffect(() => {
      // Remove the app's main navigation by letting the common store know we are in the immersive ux
      commonStore.setIsInImmersive(true);
      return () => commonStore.setIsInImmersive(false);
    }, []);

    const { enrollmentId } = params;
    useEffect(() => {
      if (!enrollmentId) {
        return;
      }

      loadEnrollment(1 * enrollmentId);
    }, [enrollmentId]);

    useEffect(() => {
      if (loadingEnrollment || !enrollment) {
        return;
      }

      const { hasCurriculumItems } = enrollment;
      if (hasCurriculumItems) {
        // Find the first item that's undone and redirect to it
        const itemId = findFirstIncompleteItemId();
        if (!itemId) {
          leaveImmersiveWithError('missingFirstToDoItemOnInit');
          return;
        }
        // Move along to our item page!
        navigate(`/immersive/${enrollmentId}/item/${itemId}`, { replace: true });
        return;
      }
      // Find the first incomplete activity id (or the first activity if all are complete)
      const activityId = findFirstIncompleteActivityId();
      if (!activityId) {
        leaveImmersiveWithError('missingFirstToDoActivityIdOnInit');
        return;
      }
      // Move along to our activity page!
      navigate(`/immersive/${enrollmentId}/activity/${activityId}`, { replace: true });
    }, [loadingEnrollment, enrollment]);

    return <LoadingPage message="Loading your enrollment..." />;
  })
);

export default ImmersiveJustEnrollment;
