import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { throttle } from 'lodash';
import { Userpilot } from 'userpilot';
import { useNavigate } from 'react-router-dom';
import Agents from '../agents/agents';
import BugsnagUtil from '../utils/bugsnagUtil';
import { useProfileContext } from './ProfileProvider';
import { loadCurrentCourseAndActivityData } from './ContinueLearningProvider';
import usePracticeExamScores from '../hooks/usePracticeExamScores';
import { USERPILOT_EVENTS } from '../constants';

const CareerProgramsContext = createContext();

/**
 * Career Programs Provider.
 *
 * Provider to interface with career programs, skill programs, and cert prep programs.
 * @param {*} children - React children
 * @param {*} user - User object from the userStore. Used to watch for login/logout events to reload data
 * @returns <CareerProgramsProvider.Provider />
 */
function CareerProgramsProvider({ children, user }) {
  // Progress for the current career program is tracked in the profile context via badge progress
  const { badges, fetchProfileData } = useProfileContext();

  const navigate = useNavigate();

  // Current enrolled career program
  const [currentProgramEnrollment, setCurrentProgramEnrollment] = useState(null);
  const [isLoadingCurrentProgram, setIsLoadingCurrentProgram] = useState(false);
  const [currentProgramError, setCurrentProgramError] = useState(null);

  // Current browse page program
  const [currentPageProgramPermalink, setCurrentPageProgramPermalink] = useState(null);
  const [currentPageProgram, setCurrentPageProgram] = useState(null);

  // Current browse page program enrollment
  const [currentPageProgramEnrollment, setCurrentPageProgramEnrollment] = useState(null);

  // All Career programs
  const [allCareerPrograms, setAllCareerPrograms] = useState(null);
  const [isLoadingAllCareerPrograms, setIsLoadingAllCareerPrograms] = useState(false);
  const [allCareerProgramsError, setAllCareerProgramsError] = useState(null);

  // Career program Browse Data
  // Index of career program browse data from the /browse route (indexed by program permalink)
  const [careerProgramBrowseData, setCareerProgramBrowseData] = useState({});
  const [isLoadingCareerProgramBrowseData, setIsLoadingCareerProgramBrowseData] = useState({});
  const [careerProgramBrowseDataError, setCareerProgramBrowseDataError] = useState({});

  // Claim credly badge error state
  const [claimCredlyBadgeError, setClaimCredlyBadgeError] = useState(null);
  const [isClaimingCredlyBadge, setIsClaimingCredlyBadge] = useState(false);

  const { practiceExamScores } = usePracticeExamScores({
    practiceExam: currentPageProgram?.practice_exam,
  });

  // Reset all state
  const reset = useCallback(() => {
    setCurrentProgramEnrollment(null);
    setIsLoadingCurrentProgram(false);
    setCurrentProgramError(null);

    setAllCareerPrograms(null);
    setIsLoadingAllCareerPrograms(false);
    setAllCareerProgramsError(null);

    setCareerProgramBrowseData({});
    setIsLoadingCareerProgramBrowseData({});
    setCareerProgramBrowseDataError({});

    setCurrentPageProgramPermalink(null);
    setCurrentPageProgram(null);
    setCurrentPageProgramEnrollment(null);

    setClaimCredlyBadgeError(null);
  }, []);

  // Reset current page state
  const resetCurrentPageState = useCallback(() => {
    setCurrentPageProgramPermalink(null);
    setCurrentPageProgram(null);
    setCurrentPageProgramEnrollment(null);
  }, []);

  /**
   * Gets a list of all career programs
   * @param {number} contentTypeId - the content type id of the career programs to get
   * @returns {Promise<null|Object>} - returns the list of career programs or null if an error occurred
   */
  const getAllCareerPrograms = useCallback(async (contentTypeId) => {
    try {
      setIsLoadingAllCareerPrograms(true);
      setAllCareerProgramsError(null);

      const programs = await Agents.careerPrograms.getCareerPrograms({ contentTypeId });

      setAllCareerPrograms(programs);
      return programs;
    } catch (getAllCareerProgramsError) {
      setAllCareerProgramsError(getAllCareerProgramsError);
      BugsnagUtil.notify(getAllCareerProgramsError);
    } finally {
      setIsLoadingAllCareerPrograms(false);
    }
    return null;
  }, []);

  /**
   * get or fetch the browse data for the given `programPermalink` and update `careerProgramBrowseData` cache
   * @returns {Promise<null|Array>} - returns the career program browse data or null if non existent
   */
  const getCareerProgramBrowseData = useCallback(
    async (programPermalink) => {
      if (!programPermalink) {
        return null;
      }

      try {
        const browseData = careerProgramBrowseData?.[programPermalink];

        if (browseData) {
          return browseData;
        }

        // Handle cache miss
        setIsLoadingCareerProgramBrowseData((prevState) => ({
          ...prevState,
          [programPermalink]: true,
        }));
        setCareerProgramBrowseDataError((prevState) => ({
          ...prevState,
          [programPermalink]: null,
        }));
        const browseDataResponse = await Agents.catalog.getContentDescriptionByPermalink(programPermalink);
        setCareerProgramBrowseData((prevState) => ({
          ...prevState,
          [programPermalink]: browseDataResponse,
        }));
        return browseDataResponse;
      } catch (error) {
        setCareerProgramBrowseDataError((prevState) => ({
          ...prevState,
          [programPermalink]: error,
        }));
        BugsnagUtil.notify(error);
      } finally {
        setIsLoadingCareerProgramBrowseData((prevState) => ({
          ...prevState,
          [programPermalink]: false,
        }));
      }
      return null;
    },
    [careerProgramBrowseData, setIsLoadingCareerProgramBrowseData, setCareerProgramBrowseData, setCareerProgramBrowseDataError]
  );

  /**
   * Loads the full program data for a given program
   * Includes enrollments with current collection / course / module / activity
   * Includes the program browse data
   * @param {Object} program - Career Program object from the API
   */
  const getFullProgramData = useCallback(
    async (program) => {
      // Note: api returns an empty array for a non existent value
      if (program && !Array.isArray(program)) {
        // Transform the program enrollments to include helpful data like current collection / course / module / activity
        if (program?.enrollments?.length) {
          await Promise.allSettled(
            program.enrollments.map(async (enrollment) => {
              const response = await loadCurrentCourseAndActivityData(enrollment);
              // eslint-disable-next-line no-param-reassign
              enrollment.transformedData = response.transformedData;
            })
          );
        }

        // Mount program browse data to program enrollment. I love this language sometimes.
        // eslint-disable-next-line no-param-reassign
        program.programBrowseData = await getCareerProgramBrowseData(program?.content_description?.permalink);

        return program;
      }
      return null;
    },
    [loadCurrentCourseAndActivityData, getCareerProgramBrowseData]
  );
  // Loads the program data for the given program id
  // If no id is provided, the current program is loaded
  const loadFullProgramData = async (id) => {
    let program;
    if (id) {
      program = await Agents.careerPrograms.getCareerProgramById(id);
    } else {
      program = await Agents.careerPrograms.getCareerProgram();
    }
    const fullProgramData = await getFullProgramData(program);
    return fullProgramData;
  };

  const handleGetCurrentProgramEnrollment = useCallback(async () => {
    try {
      setIsLoadingCurrentProgram(true);
      setCurrentProgramError(null);

      // Load the program and refresh the badge data synchronously
      const [fullProgramDataResponse] = await Promise.allSettled([loadFullProgramData(), fetchProfileData()]);
      setCurrentProgramEnrollment(fullProgramDataResponse.value);
      return fullProgramDataResponse.value;
    } catch (getCurrentProgramError) {
      setCurrentProgramError(getCurrentProgramError);
      BugsnagUtil.notify(getCurrentProgramError);
    } finally {
      setIsLoadingCurrentProgram(false);
    }
    return null;
  }, []);

  /**
   * Gets full program enrollment data for the currently enrolled program
   * Throttled to reduce the number of requests to the API while still being responsive to user actions
   * @returns {Promise<null|Object>} - returns the current enrolled career program or null if not enrolled
   */
  const getCurrentProgramEnrollment = useCallback(throttle(handleGetCurrentProgramEnrollment, 30000, { leading: true, trailing: false }), []);

  /**
   * Gets full program enrollment data for the specific program id
   * @returns {Promise<null|Object>} - returns the current enrolled career program or null if not enrolled
   */
  const getCurrentPageProgramEnrollment = useCallback(async (programId) => {
    try {
      if (!programId) {
        setCurrentPageProgramEnrollment(null);
        setCurrentProgramError(new Error("Program ID can't be null"));
        return null;
      }

      setIsLoadingCurrentProgram(true);
      setCurrentProgramError(null);

      // Load the program and refresh the badge data
      const [fullProgramDataResponse] = await Promise.allSettled([loadFullProgramData(programId), fetchProfileData()]);
      setCurrentPageProgramEnrollment(fullProgramDataResponse.value);
      return fullProgramDataResponse.value;
    } catch (getCurrentProgramError) {
      setCurrentProgramError(getCurrentProgramError);
      BugsnagUtil.notify(getCurrentProgramError);
    } finally {
      setIsLoadingCurrentProgram(false);
    }
    return null;
  }, []);

  /**
   * "Enrolls" in a career program by setting the current career program to the provided program
   * @param {string} contentDescriptionId - Content Description ID of the career program to enroll in
   * @returns {Promise<null|Object>} - returns the response from the API or null if an error occurred
   */
  const enrollInCareerProgram = useCallback(async (contentDescriptionId) => {
    try {
      setIsLoadingCurrentProgram(true);
      setCurrentProgramError(null);

      const response = await Agents.careerPrograms.setCareerProgram(contentDescriptionId);

      // Fetch current program again to update the state
      await getCurrentProgramEnrollment();

      // Reload the current program enrollment - Skip the throttle
      await handleGetCurrentProgramEnrollment();

      // Fire a UP event to track that the user enrolled in a program
      Userpilot.track(USERPILOT_EVENTS.PROGRAM_ENROLLED, { content_description_id: contentDescriptionId });

      return response;
    } catch (enrollInCareerProgramError) {
      setCurrentProgramError(enrollInCareerProgramError);
      BugsnagUtil.notify(enrollInCareerProgramError);
    } finally {
      setIsLoadingCurrentProgram(false);
    }
    return null;
  }, []);

  /**
   * "Unenroll" in a career program by setting the current career program to null
   * @returns {Promise<null|Object>} - returns the response from the API or null if an error occurred
   */
  const unenrollFromCareerProgram = useCallback(async () => {
    try {
      setIsLoadingCurrentProgram(true);
      setCurrentProgramError(null);

      const response = await Agents.careerPrograms.deleteCareerProgram();

      // Reload the current program enrollment - Skip the throttle
      await handleGetCurrentProgramEnrollment();

      // Fire a UP event to track that the user unenrolled from a program
      Userpilot.track(USERPILOT_EVENTS.PROGRAM_UNENROLLED, { content_description_id: response?.content_description_id });

      return response;
    } catch (unenrollFromCareerProgramError) {
      setCurrentProgramError(unenrollFromCareerProgramError);
      BugsnagUtil.notify(unenrollFromCareerProgramError);
    } finally {
      setIsLoadingCurrentProgram(false);
    }
    return null;
  }, []);

  const loadCurrentPageProgram = useCallback(async (programPermalink) => {
    if (programPermalink) {
      const program = await getCareerProgramBrowseData(programPermalink);
      setCurrentPageProgram(program);

      // Get unenrolled program
      if (program?.id) {
        await getCurrentPageProgramEnrollment(program?.id);
      } else {
        // Reset the current program enrollment if the program is not found
        setCurrentPageProgramEnrollment(null);
      }
    }
  }, []);

  /**
   * Reload current and browse data for a specific career program
   */
  const reload = useCallback(() => {
    // DO NOT fetch data if the user is not logged in
    if (user) {
      if (currentPageProgramPermalink) {
        loadCurrentPageProgram(currentPageProgramPermalink);
      } else {
        getCurrentProgramEnrollment();
      }
    } else {
      reset();
    }
  }, [user, currentPageProgramPermalink]);

  /**
   * INIT - Fetch data on mount
   */
  useEffect(() => {
    // Reload current program enrollment data to get the latest user progress every time the page is loaded
    reload();
  }, [user, currentPageProgramPermalink]);

  // Fetch data for the current program when the current page program permalink changes
  useEffect(() => {
    loadCurrentPageProgram(currentPageProgramPermalink);
  }, [currentPageProgramPermalink]);

  // Abstraction to check if the current page program is the current program
  const isUserEnrolledInCurrentPageProgram = useMemo(() => {
    return currentPageProgram?.id && currentPageProgram.id === currentProgramEnrollment?.content_description_id;
  }, [currentPageProgram, currentProgramEnrollment]);

  /**
   * Add required badges to all career programs
   * This is a QoL feature to avoid having to look up the required badges/progress for each career program
   */
  useEffect(() => {
    // Check if we have badges and all career programs, but have not yet added the required badges to the career programs
    if (badges?.length && allCareerPrograms?.length && !allCareerPrograms[0]?.required_badges) {
      const allCareerProgramsWithBadges = allCareerPrograms.map((allCareerProgram) => {
        // Find badges that are required for this program
        const required_badges = badges.filter((badge) => allCareerProgram.requirement_badge_ids.includes(badge.id));
        return {
          ...allCareerProgram,
          required_badges,
        };
      });
      setAllCareerPrograms(allCareerProgramsWithBadges);
    }
  }, [allCareerPrograms, badges]);

  /**
   * Add required badges to the currently enrolled career program
   * This is a QoL feature to avoid having to look up the required badges/progress for each career program
   */
  useEffect(() => {
    // Check if we have badges and all career programs, but have not yet added the required badges to the career programs
    if (badges?.length && allCareerPrograms?.length && !allCareerPrograms[0]?.required_badges) {
      const allCareerProgramsWithBadges = allCareerPrograms.map((allCareerProgram) => {
        // Find badges that are required for this program
        const required_badges = badges.filter((badge) => allCareerProgram.requirement_badge_ids.includes(badge.id));
        return {
          ...allCareerProgram,
          required_badges,
        };
      });
      setAllCareerPrograms(allCareerProgramsWithBadges);
    }
  }, [allCareerPrograms, badges]);

  const claimCredlyBadge = useCallback(async () => {
    try {
      setIsClaimingCredlyBadge(true);
      const claimEnrollmentResponse = await Agents.careerPrograms.claimProgramBadge();

      // Fire a UP event to track that the user claimed a badge
      Userpilot.track(USERPILOT_EVENTS.PROGRAM_BADGE_CLAIMED, { content_description_id: claimEnrollmentResponse?.content_description_id });

      if (claimEnrollmentResponse) {
        setCurrentProgramEnrollment(claimEnrollmentResponse);
      }
    } catch (error) {
      Bugsnag.notify(error);
      setClaimCredlyBadgeError(error);
    } finally {
      setIsClaimingCredlyBadge(false);
    }
  }, []);

  /**
   * Enter the immersive experience for the first incomplete collection in the program
   * @param {Object} program - (Optional) Career Program object from the API. If not provided, the current program enrollment is used.
   */
  const enterImmersiveForProgram = useCallback(
    async (program) => {
      const programToUse = program || currentPageProgram;
      const currentCollectionEnrollment = programToUse?.enrollments?.find((enrollment) => enrollment?.activities_completed < enrollment?.activities_total);
      if (currentCollectionEnrollment) {
        // Drop user into collection enrollment, let immersive router handle the rest
        navigate(`/immersive/${currentCollectionEnrollment?.id}`);
      }
    },
    [navigate]
  );

  const value = useMemo(
    () => ({
      // State
      // Blame Ned for the next few lines of shit :)
      // Use the current pages program enrollment if it exists, otherwise use the global one
      currentProgramEnrollment: currentPageProgramEnrollment || currentProgramEnrollment,
      // expose the global program enrollment for convenience
      currentGlobalProgramEnrollment: currentProgramEnrollment,
      currentProgramError,
      isLoadingCurrentProgram,
      allCareerPrograms,
      isLoadingAllCareerPrograms,
      allCareerProgramsError,
      careerProgramBrowseData,
      careerProgramBrowseDataError,
      isLoadingCareerProgramBrowseData,
      currentPageProgram,
      currentPageProgramPermalink,
      currentPageProgramEnrollment,
      isUserEnrolledInCurrentPageProgram,
      claimCredlyBadgeError,
      isClaimingCredlyBadge,
      practiceExamScores,
      // Mutations
      setCurrentPageProgramPermalink,
      // Actions
      getAllCareerPrograms,
      getCurrentProgramEnrollment,
      getCareerProgramBrowseData,
      enrollInCareerProgram,
      unenrollFromCareerProgram,
      enterImmersiveForProgram,
      claimCredlyBadge,
      reload,
      reset,
      resetCurrentPageState,
      // State from Child Hooks for convenience
      badges,
    }),
    [
      currentProgramEnrollment,
      currentProgramError,
      isLoadingCurrentProgram,
      allCareerPrograms,
      isLoadingAllCareerPrograms,
      allCareerProgramsError,
      careerProgramBrowseData,
      careerProgramBrowseDataError,
      isLoadingCareerProgramBrowseData,
      currentPageProgram,
      currentPageProgramPermalink,
      currentPageProgramEnrollment,
      isUserEnrolledInCurrentPageProgram,
      practiceExamScores,
      claimCredlyBadgeError,
      isClaimingCredlyBadge,
      badges,
      setCurrentPageProgramPermalink,
      getAllCareerPrograms,
      getCurrentProgramEnrollment,
      getCareerProgramBrowseData,
      enrollInCareerProgram,
      unenrollFromCareerProgram,
      reload,
      enterImmersiveForProgram,
      reset,
      resetCurrentPageState,
      // State from Child Hooks for convenience
      badges,
    ]
  );

  return <CareerProgramsContext.Provider value={value}>{children}</CareerProgramsContext.Provider>;
}

/** Hook to easily use Career Programs Context */
export const useCareerPrograms = () => useContext(CareerProgramsContext);

export default CareerProgramsProvider;
