import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useImmersive } from '../../providers/ImmersiveProvider';
import LoadingPage from '../LoadingPage/LoadingPage';

/**
 * This page controls the immersive when we have an enrollment with a curriculum item, but no activity is known yet.
 * We load the enrollment, then the item, and determine the correct activity to send the user to, and redirect.
 * Example url: /immersive/:enrollmentId/item/:itemId
 */
const ImmersiveEnrollmentWithItemNoActivity = inject('commonStore')(
  observer(({ commonStore }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { enrollment, loadEnrollment, loadItem, item, findFirstIncompleteActivityIdFromItem, leaveImmersiveWithError } = useImmersive();

    useEffect(() => {
      // Remove the app's main navigation by letting the common store know we are in the immersive ux
      commonStore.setIsInImmersive(true);
      return () => commonStore.setIsInImmersive(false);
    }, []);

    const { enrollmentId, itemId } = params;
    useEffect(() => {
      if (!enrollmentId) {
        return;
      }

      loadEnrollment(1 * enrollmentId);
    }, [enrollmentId]);

    useEffect(() => {
      if (!enrollment || !itemId) {
        return;
      }

      loadItem(1 * itemId);
    }, [itemId, enrollment]);

    useEffect(() => {
      if (!item) {
        return;
      }
      const activityId = findFirstIncompleteActivityIdFromItem();
      if (!activityId) {
        // If we didn't find an activity within the item, check if the item is a standalone activity
        const isStandaloneActivity = item?.content_description?.content_type?.is_activity;

        // If the item is a standalone activity, we need to drop the user into the activity directly
        // Stand alone activities are deprecated, but we still need to support them for now
        if (isStandaloneActivity) {
          const standaloneActivityId = item?.content_description?.id;
          navigate(`/immersive/${enrollmentId}/item/enrollment/activity/${standaloneActivityId}`, { replace: true });
          return;
        }
        leaveImmersiveWithError('missingFirstToDoActivityIdOnInit');
        return;
      }
      navigate(`/immersive/${enrollmentId}/item/${itemId}/activity/${activityId}`, { replace: true });
    }, [item]);

    return <LoadingPage message="Finding your activity..." />;
  })
);

export default ImmersiveEnrollmentWithItemNoActivity;
