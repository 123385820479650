const EXAMPLE_PROMO = {
  // Enable or disable the promo
  isEnabled: false, // Default: false

  // Snowplow tracking labels
  spLabel: 'examplePromo', // Required - the label/key of the promo
  spCategory: 'CIPUpgradeBanner', // Required - The type of promo

  // Show the promo based on the user's role/state
  isShownPublic: false, // Wether or not logged out users should see the promo - Default: false
  isShownTeamLearner: true, // team role = 'member' - Default: false
  isShownTeamAdmin: true, // team role = 'admin' - Default: false
  isShownTeamOwner: true, // team role = 'owner' - Default: false
  isShownCipFree: true, // subscription = 'free' - Default: false
  isShownCipMonthly: false, // subscription = 'monthly' - Default: false
  isShownCipQuarterly: false, // subscription = 'quarterly' - Default: false
  isShownCipAnnual: false, // subscription = 'annual' - Default: false
  isShownCipBiennial: false, // subscription = 'biennial' - Default: false
  isShownCipEduGovMilFree: false, // subscription = 'free' - Default: false
  isShownCipEduGovMilMonthly: false, // subscription = 'monthly' - Default: false
  isShownCipEduGovMilQuarterly: false, // subscription = 'quarterly' - Default: false
  isShownCipEduGovMilAnnual: false, // subscription = 'annual' - Default: false
  isShownCipEduGovMilBiennial: false, // subscription = 'biennial' - Default: false

  // Configure the attributes of the banner
  title: 'title', // Required
  body: 'body', // Required
  ctaButtonText: 'ctaButtonText', // Required
  footer: 'footerMessage', // Optional - Default: null
  iconName: 'unlock', // Optional - Default: 'unlock' @see https://app.blackwaterbay.cybrary.it/icons for available icons

  // Configure CTA Link
  targetUrl: '/upgrade/checkout?discountId=dsc_1234567890&plan=annual&lockPlan=1', // Default: null

  // (Optional) Priority of the promo. Higher number = shown first.
  priority: 1, // Default: 1

  // (Optional) Enable or disable the ability to dismiss the promo and delay the ability to re-show it
  isDismissible: false, // Default: false
  // (Optional) Time in minutes before the promo can be shown again after being dismissed
  delayOnDismiss: 60, // Default: 60
  dismissedKey: 'exampleDismissed', // Unique key to identify the promo in the cookie when dismissed

  // (Optional) Start date of the promo
  startDate: '2024-11-01T00:00:00-05:00', // Default: Disabled
  // (Optional) Expiration date of the promo
  endDate: '2024-12-01T00:00:00-05:00', // Default: Disabled

  // (Optional) Show the promo on specific pages. Uses regex matching on the current pathname
  isShownOnPages: [/\/upgrade/, /\//], // Default: Shows on all applicable banner pages

  // (Optional) Group users by last digit of their ID for a/b testing
  // This gets joined and added to the spLabel to create the event label 'examplePromo|group0123456789'
  abUserIdGroups: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], // Default: Shows to all users

  // (Optional) Random chance of this promo being shown to a user
  // This is a number between 0.0 and 1.0 (0% and 100%)
  abShowChance: 0.99, // Default: Disabled

  // (Optional) Configure the classes of the banner
  titleClassName: '', // Optional
  bodyClassName: '', // Optional
  iconClassName: '', // Optional
  footerClassName: '', // Optional
  ctaButtonClassName: '', // Optional
  bannerClassName: '', // Optional

  // (Optional) Background image for the banner
  backgroundImageUrl: '', // Optional
  backgroundImageFade: 0.6, // Optional - Default: 0.6 = 60% opacity black overlay

  // OR pass in an UpgradePromoBanner component. This will override the title, body, iconName, and targetUrl
  component: null,
};

export default EXAMPLE_PROMO;
