import React, { useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { inject, observer } from 'mobx-react';
import nav, { isActive, findSecondaryNavItems } from '../../navigation/nav';
import AddLink from '../AddLink/AddLink';
import TeamNavLogos from '../Enterprise/TeamNavLogos';
import TeamsDropdown from './TeamsDropdown';
import FormatUtil from '../../utils/formatUtil';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import { useCybMeasure } from '../../providers/CybMeasureProvider';
import If from '../If/If';
import HeaderPromoBanner from '../Banners/HeaderPromoBanner';

const BANNER_EXEMPT_URL_MATCHES = ['immersive'];

function showNavItem(item, userStore) {
  const { display, dynamicDisplayType } = item;

  // `dynamicDisplayType` overrides `display` property
  if (dynamicDisplayType) {
    return true;
  }
  // Check to see if we should even display this item
  if (display && !display(userStore)) {
    return false;
  }
  return true;
}

// eslint-disable-next-line no-unused-vars
function SecondaryLink({ item, last, userStore, i, location }) {
  const { href, label, hrefReplace, trackClickEvent, discordModal, newTab, disabled } = item;
  // Check to see if we should even display this item
  if (!showNavItem(item, userStore)) {
    return null;
  }

  const active = isActive(item, 1, userStore, location);

  const mergedLinkClasses = useMemo(() => {
    const uniqueClass = `secondary-nav-${FormatUtil.slugify(label)}`;
    const linkMarginClasses = last ? 'ml-4' : 'mx-4';
    const activeToggleClasses = active ? 'border-cyb-pink-500 text-black font-semibold' : 'border-gray-200 text-gray-600';
    const linkCursorClasses = twMerge('cursor-pointer', active ? 'cursor-default' : '', disabled ? 'cursor-not-allowed' : '');
    const linkHoverClasses = active || disabled ? '' : 'hover:text-black';
    const linkClasses = `cursor-pointer whitespace-nowrap text-normal text-sm border-b-2 pb-3 pt-3.5`;
    return twMerge(uniqueClass, linkMarginClasses, linkClasses, linkHoverClasses, linkCursorClasses, activeToggleClasses);
  }, [active, disabled, label, last]);

  const linkHref = useMemo(() => {
    // If the item is disabled, don't return a link
    if (disabled) {
      return '';
    }

    // Some URLs require dynamic replacement values, send along the href and the user store to the provided function
    if (hrefReplace) {
      return hrefReplace(href, userStore);
    }
    return href;
  }, [href, hrefReplace, userStore]);

  const onClick = useCallback(() => {
    if (trackClickEvent) {
      trackSnowplowEvent({ category: 'Navigation', action: 'click', label, property: { label, href } });
    }
  }, [href, label, trackClickEvent]);

  if (discordModal) {
    // This is a special type of link that toggles the slack modal
    return (
      <>
        {!!i && i > 0 && <div className="my-4 w-px bg-gray-600" />}
        <AddLink className={mergedLinkClasses} to={linkHref} onClick={onClick}>
          {label}
        </AddLink>
      </>
    );
  }

  const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <>
      {!!i && i > 0 && <div className="my-4 w-px bg-gray-600" />}
      <AddLink className={mergedLinkClasses} to={linkHref} onClick={onClick} {...newTabProps} active={active}>
        {label}
      </AddLink>
    </>
  );
}

function DynamicSecondaryLink(props) {
  const { item, userStore } = props;

  const { isTeamLearner, isLoadingBaselineGoals, learnerBaselineGoals } = useCybMeasure();

  // ** Display Conditions ** //

  // `isTeamLearnerWithBaselineGoal` determines if auth user is a team learner and has been assigned a baseline goal
  const isTeamLearnerWithBaselineGoal = useMemo(() => {
    const orgId = userStore?.teamData?.id;

    if (isTeamLearner) {
      return Boolean(learnerBaselineGoals[orgId]?.length > 0);
    }

    return null;
  }, [isTeamLearner, learnerBaselineGoals, userStore?.teamData?.id]);

  // ** Computed Values ** //

  const shouldDisplayLink = useMemo(() => {
    if (item.dynamicDisplayType === 'TEAM_LEARNER_BASELINE_GOAL') {
      if (isTeamLearnerWithBaselineGoal !== null) {
        return isTeamLearnerWithBaselineGoal;
      }
    }

    if (item.display) {
      return item.display(userStore);
    }

    return true;
  }, [item.dynamicDisplayType, isTeamLearnerWithBaselineGoal]);

  const isLoadingData = useMemo(() => {
    if (item.dynamicDisplayType === 'TEAM_LEARNER_BASELINE_GOAL') {
      return isLoadingBaselineGoals;
    }

    return false;
  }, [item.dynamicDisplayType, isLoadingBaselineGoals]);

  return (
    <>
      <If condition={isLoadingData}>
        <div className="flex flex-col justify-center items-center py-4 mx-4">
          <div className="w-7 h-0.5 bg-gray-400/80 rounded-full" />
        </div>
      </If>
      <If condition={shouldDisplayLink}>
        <SecondaryLink {...props} />
      </If>
    </>
  );
}

function SecondaryNavbarComponent({ userStore, navigate, commonStore }) {
  const location = useLocation() || window.location;
  const items = findSecondaryNavItems(userStore, location);
  const { team } = userStore;
  const { secondaryDesktopRight } = nav;

  const rightsideItems = secondaryDesktopRight.items || [];
  const actualRightsideItems = rightsideItems.filter((item) => showNavItem(item, userStore));
  const hasRightsideItems = !!actualRightsideItems.length;
  const leftsideItems = items || [];
  const actualLeftsideItems = leftsideItems.filter((item) => showNavItem(item, userStore));
  const hasItems = !!actualLeftsideItems.length;
  const doesNotHaveItems = !hasItems && !hasRightsideItems;
  const borderTop = doesNotHaveItems ? 'border-b border-gray-400' : '';
  const shouldShowBanners = BANNER_EXEMPT_URL_MATCHES.every((match) => !location.pathname.includes(match));
  const isDashboard = location.pathname === '/';

  // Track
  useEffect(() => {
    if (doesNotHaveItems) {
      commonStore.addCybRouterClass('no-secondary-nav');
    } else {
      commonStore.removeCybRouterClass('no-secondary-nav');
    }
  }, [hasItems, hasRightsideItems, doesNotHaveItems]);

  // For tracking the number of right side items...
  let countRightSideItems = 0;

  return (
    <div className="bg-gray-200">
      <div className={`lg:flex justify-between items-center max-w-screen-2xl mx-auto pl-4 pr-8 hidden secondary-navbar ${borderTop} mb-1`}>
        <div className="flex-1">
          <nav className="flex" aria-label="Secondary Navigation">
            {hasItems &&
              actualLeftsideItems.map((item) => {
                if (item.divider) {
                  return <div key={item.id} className="my-4 mx-1 w-px bg-gray-500" />;
                }
                if (item.teams) {
                  return <TeamsDropdown userStore={userStore} key={item.id} navigate={navigate} isAdminOnly />;
                }
                if (item.dynamicDisplayType) {
                  return <DynamicSecondaryLink key={item.id} item={item} userStore={userStore} location={location} />;
                }
                return <SecondaryLink key={item.id} item={item} userStore={userStore} location={location} />;
              })}
          </nav>
        </div>
        <div className="shrink">
          <div className="flex">
            {hasRightsideItems &&
              actualRightsideItems.map((item, i) => {
                const { display, id } = item;
                if (display && !display(userStore)) {
                  return null;
                }
                if (id === 'team-logos') {
                  return (
                    <TeamNavLogos
                      key={id}
                      teams={userStore.userTeams}
                      isLeaderboardDrawerOpen={commonStore.isLeaderboardDrawerOpen}
                      toggleLeaderboardDrawer={commonStore.setIsLeaderboardDrawerOpen}
                      isLeaderboardEnabled={team?.show_team_leaderboard}
                    />
                  );
                }
                if (id === 'team-selector') {
                  return (
                    <div className="my-3" key={item.id}>
                      <TeamsDropdown
                        userStore={userStore}
                        navigate={navigate}
                        isLeaderboardDrawerOpen={commonStore.isLeaderboardDrawerOpen}
                        toggleLeaderboardDrawer={commonStore.setIsLeaderboardDrawerOpen}
                        isLeaderboardEnabled={Boolean(team?.show_team_leaderboard && isDashboard)}
                        position="right"
                      />
                    </div>
                  );
                }
                const isLast = i + 1 === actualRightsideItems.length;
                countRightSideItems++;
                return <SecondaryLink key={id} item={item} last={isLast} userStore={userStore} i={countRightSideItems - 1} location={location} />;
              })}
          </div>
        </div>
      </div>
      <If condition={shouldShowBanners}>
        <HeaderPromoBanner />
      </If>
    </div>
  );
}

const SecondaryNavbar = inject('userStore', 'commonStore')(observer(SecondaryNavbarComponent));

export default SecondaryNavbar;
